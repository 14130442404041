import React, { FC } from "react"
import loadable from "@loadable/component"

import ArrowLink from "components/elements/Link"
import { BlogCardProps } from "../../elements/BlogCard/index"
import BlogCard from "../../elements/BlogCard/index"
import { v4 as uuid } from "uuid"

const Slider = loadable(() => import("../Slider"))

export interface BlogRowProps {
  title?: string
  handle?: string
  linkText?: string
  posts: BlogCardProps[]
}

const BlogRow: FC<BlogRowProps> = ({ title, handle, posts, linkText }) => {
  return (
    <div className="my-10 sm:my-16">
      <div className="flex flex-row justify-between mb-6">
        {title ? (
          <h2 className="text-2xl md:text-xl lg:text-3xl text-idc-title font-bold">
            {title}
          </h2>
        ) : null}
        <div className="hidden md:flex flex-col justify-end">
          {handle ? (
            <ArrowLink
              text={linkText ? linkText : "See more articles"}
              url={handle}
              sizeClass="text-lg"
            />
          ) : null}
        </div>
      </div>
      <div className="md:hidden">
        {posts ? (
          <Slider
            items={posts.map((post) => (
              <BlogCard {...post} key={uuid()} boldText={true} />
            ))}
          />
        ) : null}
      </div>
      <div className="md:grid grid-cols-3 gap-8 hidden ">
        {posts
          ? posts.map((post) => (
              <BlogCard {...post} key={uuid()} boldText={true} />
            ))
          : null}
      </div>

      <div className="flex md:hidden flex-row justify-end mt-5">
        {handle ? (
          <ArrowLink
            text={linkText ? linkText : "See more articles"}
            url={handle}
          />
        ) : null}
      </div>
    </div>
  )
}

export default BlogRow
