import React, { FC } from "react"
import { PlayIcon } from "@heroicons/react/solid"
import ArrowLink from "components/elements/Link"

import StrapiImage from "components/elements/StrapiImage"
import "react-lazy-load-image-component/src/effects/blur.css"

export interface BlogCardProps {
  boldText?: boolean
  title: string
  description: string
  image: { url: string }
  handle: string
  type?: {
    color?: string
    handle?: string
    strapiId?: string
    title?: string
    videoArticles?: boolean
  }
}

const BlogCard: FC<BlogCardProps> = ({
  title,
  description,
  image,
  handle,
  type = {},
  boldText = false,
}) => {
  // console.log("type", type)
  return (
    <div className="max-w-2xl">
      <div className="h-[14.375rem] relative">
        {type && (type >= 1 || type.videoArticles) && (
          <PlayIcon className="absolute bottom-[0.875rem] left-[0.875rem] h-10 w-10 text-white" />
        )}
        <StrapiImage
          image={image}
          className="object-contain object-center w-full h-full rounded-xl"
          loading="lazy"
        />
      </div>
      <div className="mt-4 text-left">
        <h3
          className={`${
            boldText ? "text-lg font-bold" : "text-xl"
          } text-title mb-2`}
        >
          {title}
        </h3>
        <p className="mb-4 text-sm sm:text-base text-idc-body sm:mb-6 line-clamp-3">
          {description}
        </p>
        <ArrowLink text="Read more" url={`/blog/${handle}`} />
      </div>
    </div>
  )
}

export default BlogCard
