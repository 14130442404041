import * as React from "react"
import { graphql } from "gatsby"
import { Seo } from "components/seo"
import { Layout } from "components/layouts/Layout/layout"
import BlogRow from "components/layouts/BlogRow"
import Container from "components/elements/Container"

export default function ArticleTypeIndex({
  data: {
    articles: { nodes },
    articleType,
  },
}) {
  return (
    <Layout>
      <Seo title={articleType.title} />
      <Container>
        <div className="flex flex-row justify-center">
          <div className="max-w-[76rem] w-full mx-auto">
            <div className="flex flex-row justify-center mt-10 lg:mt-20">
              <div
                className={`inline-block rounded-full py-2 px-6 mb-4`}
                style={{ backgroundColor: articleType.color }}
              >
                <h4 className="uppercase text-2xl text-[#432B25] lg:text-4xl font-bold">
                  {articleType.title}
                </h4>
              </div>
            </div>
            <BlogRow posts={nodes} />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($handle: String!) {
    articleType: strapiArticleTypes(handle: { eq: $handle }) {
      color
      title
    }
    articles: allStrapiArticles(filter: { type: { handle: { eq: $handle } } }) {
      nodes {
        handle
        title
        id
        description
        image {
          url
        }
        type {
          videoArticles
        }
      }
    }
  }
`
