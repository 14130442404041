import React, { FC } from "react"
import { getResourceUrl } from "utils/strapi"

export interface StrapiImageProps {
  image: Array
}

const StrapiImage: FC<StrapiImageProps> = ({ image, ...props }) => {
  return (
    <img
      alt={image.alternativeText}
      src={`${
        image.formats?.small
          ? getResourceUrl(image.formats?.thumbnail.url)
          : getResourceUrl(image.url)
      }`}
      srcSet={`
        ${
          image.formats?.small
            ? `${getResourceUrl(image.formats?.small.url)} 500w,`
            : ``
        }
        ${
          image.formats?.large
            ? `${getResourceUrl(image.formats?.medium.url)} 750w,`
            : ``
        }
        ${
          image.formats?.large
            ? `${getResourceUrl(image.formats?.large.url)} 1000w,`
            : ``
        }
        ${
          image.formats?.xlarge
            ? `${getResourceUrl(image.formats?.xlarge.url)} 1920w,`
            : ``
        }
      `}
      {...props}
    />
  )
}

export default StrapiImage
